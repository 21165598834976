<template>
  <div>
    <component
      :is="ghsComponent"
      :param.sync="popupParam"
    />
  </div>
</template>

<script>
export default {
  name: 'chem-ghs',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        usageCd: '',  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        msdsNo: '',  // MSDS번호
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고 환산계수
        inConversonUnitCd: null,  // 입고 환산단위
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '',  // 미사용사유
        preMdmChemMaterialId: '',  // 이전화학자재 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      // tab: 'chemGhs1',
      // tabItems: [
      //   { name: 'chemGhs1', icon: 'groups', label: '1. 구성물질별 GHS 분류데이터 (국가별)', component: () => import(`${'./chemGhs1.vue'}`) },
      //   { name: 'chemGhs2', icon: 'list', label: '2. 제품 GHS 혼합분류 결과', component: () => import(`${'./chemGhs2.vue'}`) },
      //   { name: 'chemGhs3', icon: 'list', label: '3. 제품 GHS 분류근거 DB', component: () => import(`${'./chemGhs3.vue'}`) },
      // ],
      ghsComponent: () => import(`${'./chemGhs2.vue'}`)
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>